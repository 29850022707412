import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import React, { useEffect } from "react";
import Navbar from './Components/Navbar';
import ScrollToTop from './Components/ScrollToTop';
// import * as React from 'react';
import { Helmet } from "react-helmet";
import KnowledgeBank from './Components/KnowledgeBank';
import Api from './Components/Api';
import Posts from './Components/Posts';
import Blog from './Components/Blog';
import BlogEntry from './Components/BlogEntry';



 const Home = React.lazy(() => import("./Components/Home"))

const About_us = React.lazy(() => import("./Components/About_us"))
const Advisory_Board = React.lazy(() => import("./Components/Advisory"))
const Case_studies = React.lazy(() => import("./Components/Case_studies"))
const Case_study_1_GPS = React.lazy(() => import("./Components/Case_study_1_GPS"))
const Case_study_2 = React.lazy(() => import("./Components/Case_study_2"))
const Case_study_3 = React.lazy(() => import("./Components/Case_study_3"))
const Case_study_4 = React.lazy(() => import("./Components/Case_study_4"))
const Case_study_5 = React.lazy(() => import("./Components/Case_study_5"))
const Case_study_6 = React.lazy(() => import("./Components/Case_study_6"))
const ContactUs = React.lazy(() => import("./Components/ContactUs"))
const Careers = React.lazy(() => import("./Components/Careers"))
const Lynkhub = React.lazy(() => import("./Components/Lynkhub"))

function App() {

  let version=''
  let versionMismatch=false
  // const [version,setVersion]=useState('')  

  useEffect(() => {
    version=localStorage.getItem("version")
    setInterval(() => {
      versionCheck()
    }, 60000);  

    function versionCheck(){
      // console.log("Version Check",new Date());
      fetch("/assets/version.json?t="+new Date().getTime(),{
        method: "GET",
        headers:{
          "Content-Type": "application/json"
        }
      })
      .then((response) => response.json())
      .then((version1)=>{
        if(version1.hash && version1.hash!=version){
          console.log("Will reload.....");
          if(!versionMismatch){
            versionMismatch=true
            setTimeout(() => {
              localStorage.setItem("version",version1.hash)
              window.location.reload(true)
            }, 2000);
          }
        }
      })
      .catch((err)=>{
        console.log(err);
      })
    }
  }, [])


  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lynkit - Orchestrating Efficiency In Your Supply Chain </title>
        <meta name="description" content="Automate and digitally transform your supply chain with Lynkit's blockchain, telematics and IoT based logistics optimisation solutions." />
        <link rel="icon" type="image/png" href="lynkit.ico" sizes="32x32" />

      </Helmet>
      <React.Suspense fallback={<p></p>}>
        <Switch>
           <Route exact path="/" component={Home} /> 

          <Route  path="About_us?key=2222218888" component={About_us} />

          <Route exact path="/Company" component={Home} />
          <Route exact path="/Home/:viewToScroll" component={Home} /> 
          <Route exact path="/Home" component={Home} />


          
          
          <Route exact path="/About_us" component={About_us} />
          <Route exact path="/About_us/:viewToScroll" component={About_us} />
          <Route exact path="/About_us/:viewToScroll" component={About_us} />
          <Route exact path="/Advisory_Board" component={Advisory_Board} />
          <Route exact path="/Case_studies" component={Case_studies} />
          <Route exact path="/knowledge-bank" component={KnowledgeBank} />
          <Route exact path="/Api" component={Api} />
          <Route exact path="/knowledge-bank/posts" component={Posts} />
          <Route exact path="/Lynkhub" component={Lynkhub} />
          <Route exact path="/blog" component={Blog} />
          {/* <Route exact path="/blog/:id" component={BlogEntry} /> */}
          <Route  path="/blog/:index" component={BlogEntry} /> 


         


          <Route exact path="/Case_study_1_GPS" component={Case_study_1_GPS} />
          <Route exact path="/Case_study_2" component={Case_study_2} />
          <Route exact path="/Case_study_3" component={Case_study_3} />
          <Route exact path="/Case_study_4" component={Case_study_4} />
          <Route exact path="/Case_study_5" component={Case_study_5} />
          <Route exact path="/Case_study_6" component={Case_study_6} />
          <Route exact path="/ContactUs" component={ContactUs} />
          <Route exact path="/Careers" component={Careers} />
          

        </Switch>
      </React.Suspense>





    </>

  );  
};

export default App;
